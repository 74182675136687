<template>
  <div></div>
</template>

<script>
export default {
  props: {
    id: { require: true },
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
